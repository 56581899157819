import { takeLatest, call, put, all } from 'redux-saga/effects';
import { Types } from '~/store/modules/types';
import { requestRelatorioBancoHoras } from '~/services/request';

export function* request({
  url,
  method,
  queryParams,
  body,
  responseAction,
  callback,
}) {
  const params = {
    url,
    method,
    queryParams,
    body,
  };

  try {
    const response = yield call(requestRelatorioBancoHoras, params);
    yield put(responseAction(response.data, callback));
  } catch (error) {
    yield put(responseAction({ success: false, message: 'Erro' }, callback));
  }
}

export default all([
  takeLatest(Types.REQUEST_PGTO_ABONO_EXTRAORDINARIO, request),
]);
