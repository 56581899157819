/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import { theme } from '~/styles';

import './config/ReactotronConfig';

import Routes from './routes/index';
import history from './services/history';

import { store, persistor } from './store';

import Provedor from './hooks';
import AuthProvider from './authProvider';
import Firebase from './firebase';

const trackingId = process.env.TRACKING_ID;
ReactGA.initialize(trackingId);

// Sentry.init({
//  dsn: 'https://c6af6bac7672413aabf9b7b02c37caeb@o266934.ingest.sentry.io/4505556367310848',
//  integrations: [
//    new Sentry.BrowserTracing({
//      // See docs for support of different versions of variation of react router
//      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
//      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//        React.useEffect,
//        useLocation,
//      ),
//    }),
//    new Sentry.Replay(),
//  ],
//
//  // Set tracesSampleRate to 1.0 to capture 100%
//  // of transactions for performance monitoring.
//  tracesSampleRate: 1.0,
//
//  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//
//  tracePropagationTargets: [
//    'PROD',
//    /^https:\/\/portal\.folhacerta\.com\/login\//,
//    'STAG-PROD-DB',
//    /^https:\/\/hom-portal\.folhacerta\.com\/login\//,
//    'STAG',
//    /^https:\/\/dev-portal\.folhacerta\.com\/login\//,
//    'LOCAL',
//    /^http:\/\/localhost:3000\//,
//  ],
//
//  // Capture Replay for 10% of all sessions,
//  // plus for 100% of sessions with an error
//  replaysSessionSampleRate: 0.1,
//  replaysOnErrorSampleRate: 1.0,
// });

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    //    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate persistor={persistor}>
          <Provedor>
            <Router history={history}>
              <Routes />
              <AuthProvider />
              {!window.matchMedia('(max-width: 768px)').matches && <Firebase />}
            </Router>
          </Provedor>
        </PersistGate>
      </ThemeProvider>
    </Provider>
    //    </Sentry.ErrorBoundary>
  );
};

export default App;
